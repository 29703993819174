import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Image from "../../composants/image/Image"
import LayoutTutorielFR from "../../composants/layout/fr/LayoutTutorielFR"
import Section from "../../composants/layout/Section"

import {
  obtenirImage,
  obtenirImages,
  obtenirPage,
  obtenirCartesLiensVersAutresPages,
} from "../../js/client-es/utils"
import fragmentsImages from "../../js/client-es/fragments/fragments-images"
import fragmentsPages from "../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    pagePaletteHubble22: allPagesCompilationYaml(
      filter: { code: { eq: "paletteHubble22" } }
    ) {
      ...FragmentPageYaml
    }
    pagesPourAllerPlusLoin: allPagesCompilationYaml(
      filter: {
        code: {
          in: [
            "paletteHubble12"
            "paletteHubblePhotoshop"
            "galerieNebuleusesPaletteHubble"
          ]
        }
      }
      sort: { fields: ordre_carte, order: ASC }
    ) {
      ...FragmentCarteLienVersPage
    }
    imagesPaysagePetit: allImagesCompilationYaml(
      filter: {
        code: {
          in: [
            "astrophotographieCCDLespectreElectroMagnetique"
            "astrophotographieCCDLaluminescence"
            "astrophotographieCCDCouleursDeLaSerieDeBalmer"
            "astrophotographieCCDLaSerieDeBalmer"
            "astrophotographieCCDLemodelePlanetaireDeBohr-Rutherford"
          ]
        }
      }
    ) {
      ...FragmentImagePaysagePetit
    }
    imagesPaysageMicro: allImagesCompilationYaml(
      filter: {
        code: { in: ["astrophotographieCCDLemodeleDeBohr-Rutherford"] }
      }
    ) {
      ...FragmentImagePaysageMicro
    }
  }
`

export default function PaletteHubble22() {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "pagePaletteHubble22")
  const pagesPourAllerPlusLoin = obtenirCartesLiensVersAutresPages(
    resultatsRequete,
    "pagesPourAllerPlusLoin"
  )
  const images = obtenirImages(
    resultatsRequete,
    "imagesPaysagePetit",
    "imagesPaysageMicro"
  )

  return (
    <LayoutTutorielFR
      page={page}
      pagesPourAllerPlusLoin={pagesPourAllerPlusLoin}
    >
      <Section titre="La nature de la lumière">
        <p>
          La lumière est un <strong>rayonnement électromagnétique</strong>. Ces
          rayonnements électromagnétiques sont des ondes, avec des
          caractéristiques qui leur sont propres (fréquence, longueur d’onde,
          phase). Sur Terre, nous <strong>« baignons »</strong> dans un
          environnement électromagnétique. Voici quelques exemples de
          rayonnements :{" "}
        </p>
        <ul>
          <li>La lumière bien sûr</li>
          <li>Le wifi de votre box internet</li>
          <li>Votre téléphone portable</li>
          <li>La radio, la TV</li>
          <li>Les télécommandes de vos appareils électroniques</li>
          <li>Les radars</li>
          <li>Les rayons X pour réaliser les radiographies à l’hôpital</li>
          <li>Etc etc.</li>
        </ul>
        <p>
          Chaque exemple cité est lié à une gamme de rayonnements
          électromagnétiques qui lui est propre. Cependant, nous n’en percevons
          qu’une toute petite partie : ceux dont la longueur d’onde correspond à
          la lumière.
        </p>
        <Image
          image={obtenirImage(
            images,
            "astrophotographieCCDLespectreElectroMagnetique"
          )}
          langue={page.langue}
        />
        <p>
          <strong>
            Plus la fréquence (nombre d’oscillations par secondes) de l’onde
            électromagnétique est importante, plus elle contient d’énergie
          </strong>
          . C’est pourquoi les rayons gamma (v. le schéma ci-dessus) sont très
          dangereux et qu’ils sont confinés dans d’épaisses carcasses de bétons
          dans les centrales nucléaires.
        </p>
        <p>
          {" "}
          Cette énergie est délivrée lors de l’« impact » de l’onde sous la
          forme d’une petite particule dont le nom vous est sans doute familier
          : le photon.
        </p>
      </Section>
      <Section titre="L’origine de la lumière">
        <p>
          Plusieurs mécanismes produisent des rayonnements électromagnétiques
          (et donc de la lumière). Les principaux sont les 2 suivants :
        </p>
        <ul>
          <li>
            <strong>Le rayonnement thermique</strong>. Quand un corps chauffe,
            il émet de la lumière dont la couleur est fonction de sa
            température. C’est ce qu’il se passe lorsque le maréchal ferrant
            travaille un fer : plus le fer est chaud, plus la lumière qu’il émet
            est bleue. Plus il va refroidir, plus sa couleur virera au rouge. La
            lumière émise par le Soleil a aussi pour origine un rayonnement
            thermique.
          </li>
          <li>
            <strong>La luminescence</strong>, qui est une émission de lumière
            dont l’origine n’est pas thermique. Elle est produite par
            l’excitation d’atomes (collision avec un autre atome, absorption
            d’un photon, etc.). C’est ce mécanisme qui nous intéresse ici car il
            se produit notamment dans les grandes structures gazeuses de
            l’univers (nébuleuses, chromosphère du Soleil…). Ce mécanisme de
            luminescence est aussi à l’origine des aurores polaires, j’y
            reviendrai au cours d’un prochain article.
          </li>
        </ul>
      </Section>
      <Section titre="Structure de l’atome : le modèle de Bohr - Rutherford">
        <p>
          Il nous faut tout d’abord comprendre la structure de l’atome. Un atome
          est composé de <strong>particules</strong> : <strong>protons</strong>{" "}
          et <strong>neutrons</strong> forment son noyau, alors que{" "}
          <strong>des électrons gravitent autour</strong>. Attention, le modèle
          décrit ici est maintenant dépassé par les principes de la mécanique
          quantique, et n’est utilisé qu’à des fins pédagogiques.
        </p>
        <p>
          <strong>Rutherford</strong> a été le premier a été le{" "}
          <strong>premier</strong> à attribuer une structure planétaire à
          l’atome : les électrons sont séparés par du vide et gravitent autour
          du noyau, comme les planètes autour du système solaire.
        </p>
        <Image
          image={obtenirImage(
            images,
            "astrophotographieCCDLemodeleDeBohr-Rutherford"
          )}
          langue={page.langue}
        />
        <p>
          <strong>Niels Bohr</strong> a complété le modèle de Rutherford en y
          apportant <strong>les contraintes suivantes</strong> :
        </p>
        <ul>
          <li>
            Un électron ne peut se trouver que sur une orbite bien définie
            autour de l’atome : <strong>les couches électroniques</strong>.
          </li>
          <li>
            Les électrons ne peuvent pas changer de couche (à moins qu’ils ne
            soient excités, voir plus bas).
          </li>
          <li>
            Chacune de ces couches accueille un nombre précis d’électrons.
          </li>
          <li>
            Une couche ne peut accueillir des électrons qu’à condition que la
            précédente ait été remplie.
          </li>
        </ul>
        <p>
          Il existe 7 couches électroniques différentes : la couche{" "}
          <strong>K</strong> est <strong>la plus proche</strong> du noyau
          (niveau 1, l’orbite « fondamentale ») alors que la couche{" "}
          <strong>Q</strong> est <strong>la plus éloignée</strong> (niveau 7).
          Chacune possède un niveau d’énergie noté « n ». L’énergie la plus
          basse est celle de la couche la plus proche du noyau (couche K, n= 1),
          la plus forte est celle de la couche la plus éloignée du noyau (couche
          Q, n=7).
        </p>
        <Image
          image={obtenirImage(
            images,
            "astrophotographieCCDLemodelePlanetaireDeBohr-Rutherford"
          )}
          langue={page.langue}
        />
      </Section>
      <Section titre="La luminescence">
        <p>
          Nous venons de voir que <strong>d’après Bohr</strong>, les{" "}
          <strong>électrons</strong> ne peuvent se trouver que{" "}
          <strong>sur des orbites bien définies</strong> autour du noyau de
          l’atome et qu’ils ne pouvaient en changer.
        </p>
        <p>
          <strong>Sauf s’ils sont excités</strong> : choc avec une autre
          particule (photon, électron libre, etc.), choc avec un atome… Si
          l’énergie apportée est suffisante et n’est pas trop forte, Bohr
          précise que l’électron peut changer d’orbite, pour ensuite revenir à
          sa position d’origine. Mais{" "}
          <strong>pour revenir à sa position d’origine</strong> (une couche de
          niveau d’énergie moins élevée), l’électron doit libérer son excédent
          d’énergie sous forme de <strong>photon</strong>.
        </p>
        <p>
          <strong>Le retour à l’orbite initiale</strong> de l’électron peut se
          faire en une ou plusieurs <strong>étapes</strong> :
        </p>
        <ul>
          <li>Retour à l’orbite initiale de proche en proche</li>
          <li>Retour à l’orbite initiale en une seule fois</li>
          <li>Ou retour à l’orbite initiale en sautant quelques orbites.</li>
        </ul>
        <p>
          La longueur d’onde du photon émis sera propre au saut de retour
          effectué et à l’atome excité. Si la longueur d’onde du photon émis
          fait partie du domaine du visible (la lumière), alors nous percevrons
          une couleur.
        </p>
        <Image
          image={obtenirImage(images, "astrophotographieCCDLaluminescence")}
          langue={page.langue}
        />
        <p>
          <strong>C’est ce mécanisme</strong> qui est à l’origine de la{" "}
          <strong>luminescence</strong> : <strong>excitation</strong>, puis{" "}
          <strong>émission</strong> d’un <strong>photon</strong>. Beaucoup de
          nébuleuses émettent de la lumière par luminescence : les gaz sont
          chauffés par les étoiles qui s’y trouvent. Les atomes du nuage de gaz
          s’agitent, s’entrechoquent. Les électrons changent alors d’orbite,
          puis reviennent à leurs positions initiales en émettant de la lumière.
        </p>
        <p>
          Pour être complet sur la luminescence, sachez qu’il existe 2 types de
          luminescence :
        </p>
        <ul>
          <li>
            La fluorescence : l’émission est presque immédiate après
            l’excitation.
          </li>
          <li>
            La phosphorescence : l’émission se produit de plusieurs secondes
            jusqu’à plusieurs jours après l’excitation.
          </li>
        </ul>
      </Section>
      <Section titre="Le filtre Hα : étude de cas sur l’hydrogène">
        <p>
          La plupart des <strong>nébuleuses</strong> sont constituées{" "}
          <strong>en très grande partie d’hydrogène</strong>. Mais étudions
          pourquoi le filtre utilisé en imagerie s’appelle « alpha », et non pas
          simplement « hydrogène ».
        </p>
        <h3>L’atome d’hydrogène</h3>
        <p>
          L’atome <strong>d’hydrogène</strong> est particulier : son noyau n’est
          composé que <strong>d’un seul proton</strong>, et il ne possède{" "}
          <strong>qu’un seul électron</strong> sur sa couche périphérique.
          Lorsqu’il est stable, celui-ci se trouve donc sur la couche K (la
          première).
        </p>
        <h3>La série de Balmer</h3>
        <p>
          Nous avons vu{" "}
          <strong>
            qu’après excitation, un électron cherche à retomber sur son orbite
            initiale
          </strong>{" "}
          en émettant un photon pour se débarrasser de son excès d’énergie.{" "}
          <strong>
            La longueur d’onde de ce photon dépend de l’atome, de la position de
            départ et de la position d’arrivée de l’électron.
          </strong>
        </p>
        <p>
          Le physicien Johann Jacob Balmer a étudié{" "}
          <strong>
            l’ensemble des longueurs d’ondes des photons émis par l’hydrogène
            lorsque l’électron excité retombe sur la couche L (n=2)
          </strong>
          . Ce qui donne les combinaisons suivantes :
        </p>
        <table>
          <thead>
            <tr>
              <td>Niveau d’énergie haut</td>
              <td>Niveau d’énergie bas</td>
              <td>Notation usuelle</td>
              <td>Longueur d’onde (nm)</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>3</td>
              <td>2</td>
              <td>Hα</td>
              <td>656</td>
            </tr>
            <tr>
              <td>4</td>
              <td>2</td>
              <td>Hβ</td>
              <td>486</td>
            </tr>
            <tr>
              <td>5</td>
              <td>2</td>
              <td>Hγ</td>
              <td>434</td>
            </tr>
            <tr>
              <td>6</td>
              <td>2</td>
              <td>Hδ</td>
              <td>410</td>
            </tr>
            <tr>
              <td>7</td>
              <td>2</td>
              <td>Hε</td>
              <td>397</td>
            </tr>
          </tbody>
        </table>
        <p>
          Les longueurs d’onde <strong>Hα, Hβ, Hγ, Hδ</strong> ont la
          particularité de faire partie{" "}
          <strong>du domaine de la lumière visible</strong>. C’est pourquoi la
          série de Balmer est si <strong>intéressante</strong>. La raie Hα est
          de plus très intense dans les nébuleuses : ce filtre est donc
          extrêmement utilisé en astrophotographie.
        </p>
        <Image
          image={obtenirImage(images, "astrophotographieCCDLaSerieDeBalmer")}
          langue={page.langue}
        />
        <p>Les couleurs émises sont les suivantes :</p>
        <Image
          image={obtenirImage(
            images,
            "astrophotographieCCDCouleursDeLaSerieDeBalmer"
          )}
          langue={page.langue}
        />
        <h3>Les autres séries</h3>
        <p>
          Johann Balmer a étudié les longueurs d’ondes des photons émis lorsque
          les électrons retombent sur la couche de niveau 2. D’autres physiciens
          ont étudié ce qu’il se produisait lorsque l’électron retombe à un
          niveau d’énergie différent.{" "}
          <strong>
            Mais les longueurs d’ondes des photons émis ne font plus partie du
            visible…
          </strong>
        </p>
        <table border="1" align="center">
          <thead>
            <tr>
              <td>Nom de la série</td>
              <td>Niveau d’énergie bas</td>
              <td>Domaine</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Lyman</td>
              <td>1</td>
              <td>UV</td>
            </tr>
            <tr>
              <td>Balmer</td>
              <td>2</td>
              <td>UV / Visible</td>
            </tr>
            <tr>
              <td>Paschen</td>
              <td>3</td>
              <td>IR</td>
            </tr>
            <tr>
              <td>Balmet</td>
              <td>4</td>
              <td>IR</td>
            </tr>
            <tr>
              <td>Pfund</td>
              <td>5</td>
              <td>IR</td>
            </tr>
            <tr>
              <td>Humphreys</td>
              <td>6</td>
              <td>IR</td>
            </tr>
          </tbody>
        </table>
        <h3>Les autres atomes</h3>
        <p>
          Même pour l’atome le plus simple (l’hydrogène, un seul électron), le
          problème est complexe. Il se complique avec les autres atomes, et
          encore plus avec les molécules. Jusqu’à même devenir hors de portée
          des ordinateurs les plus puissants…
        </p>
      </Section>
      <Section titre="Les milieux ionisés">
        <h3>L’ionisation</h3>
        <p>
          La charge d’un atome est toujours <strong>neutre</strong> car il
          comporte autant de <strong>protons</strong> (charge positive) que{" "}
          <strong>d’électrons</strong> (charge négative). Par conséquent
          lorsqu’un atome…
        </p>
        <ul>
          <li>
            Perd un électron, sa charge devient positive. C’est un ion positif.
          </li>
          <li>
            Gagne un électron, sa charge devient négative. C’est un ion négatif.
          </li>
        </ul>
        <p>
          De nombreux atomes sont ionisés dans les nébuleuses. Dans ces milieux
          très agités, les sources d’excitation sont multiples : chocs entre
          atomes, excitations par des photons, etc. Si l’apport énergie est
          suffisant,{" "}
          <strong>
            certains électrons peuvent être propulsés au-delà de la dernière
            orbite d’influence du noyau de l’atome.
          </strong>
        </p>
        <p>
          L’atome devient alors un ion positif, et les électrons perdus
          deviennent des électrons libres. Ceux-ci pourront ensuite se
          recombiner avec des ions négatifs pour reformer un atome stable.
        </p>
        <h3>Notation des degrés d’ionisation</h3>
        <p>Il existe plusieurs façons de noter l’ionisation d’un atome :</p>
        <table border="1" align="center">
          <thead>
            <tr>
              <td>Degré d’ionisation</td>
              <td>Description</td>
              <td>Exemple</td>
              <td>Notation chimique</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>I</td>
              <td>Non ionisé (neutre)</td>
              <td>HI, HeI</td>
              <td>H, He</td>
            </tr>
            <tr>
              <td>II</td>
              <td>Ionisation simple (perte d’un électron)</td>
              <td>HII, HeII</td>
              <td>H+, He+</td>
            </tr>
            <tr>
              <td>III</td>
              <td>Ionisation double (perte de 2 électrons)</td>
              <td>HeIII</td>
              <td>He++</td>
            </tr>
          </tbody>
        </table>
        <h3>Les régions HII</h3>
        <p>
          Nous l’avons vu précédemment, les nébuleuses contiennent une grande
          majorité d’ions, et une grande partie d’entre elles sont composées
          essentiellement d’hydrogène. <strong>L’atome d’hydrogène</strong> ne
          pouvant être ionisé <strong>qu’une seule fois</strong>, c’est pourquoi
          les nébuleuses sont souvent nommées régions <strong>« HII »</strong>.
        </p>
        <h3>Précisions sur le rayonnement Hα</h3>
        <p>
          <strong>Il n’existe pas de filtre « HII »</strong> car les ions HII ne
          possèdent pas d’électrons (leurs uniques électrons ont été perdus).
          Ils ne peuvent donc pas émettre de photons lorsqu’ils sont excités.
        </p>
        <p>
          Mais les électrons libres sont nombreux dans les nébuleuses. Si un ion
          HII rencontre un électron libre, celui-ci sera attrapé pour reformer
          un atome d’hydrogène stable : c’est la « recombinaison ».
        </p>
        <p>
          Lors de cette recombinaison, l’électron peut se retrouver sur
          n’importe quelle orbite autour du noyau d’hydrogène. Lorsqu’il revient
          en cascade vers l’orbite de niveau 1 (la plus stable), il existe 50%
          de chance pour que l’électron réalise un saut de l’orbite de niveau 3
          vers l’orbite de niveau 2.{" "}
          <strong>
            Un photon dont la longueur d’onde correspond à la raie Hα est alors
            émis.
          </strong>{" "}
          Cette raie est donc très intense lorsque l’on observe des nébuleuses.
        </p>
      </Section>
      <Section titre="Les filtres [OIII], [SII], [NII]…">
        <p>
          Dans les nébuleuses, en plus de la raie Hα, des raies lumineuses très
          intenses de [NII] (azote ionisé), [OII], [OIII] (oxygène ionisé et
          doublement ionisé), [SII] (soufre doublement ionisé) sont aussi
          observées.
        </p>
        <p>
          La notation « <strong>[]</strong> » correspond à{" "}
          <strong>une raie interdite</strong>, c’est-à-dire qu’
          <strong>elle ne peut pas être reproduite sur Terre</strong>. Pour que
          ces ions émettent de la lumière, ils doivent rester excités pendant
          plusieurs secondes à plusieurs heures. Ensuite seulement leurs
          électrons retourneront à leur position d’origine en émettant des
          photons.
        </p>
        <p>
          Cet état d’excitation ne peut durer aussi longtemps sur Terre, car un
          atome excité peut transférer son surplus d’énergie dès qu’il rentre en
          collision avec un autre atome : c’est le mécanisme de désactivation
          collisionnelle. Il faut donc de parfaites condition de vide pour que
          des états d’excitation aussi longs se produisent. Ces conditions de
          vide ne sont pas reproduisibles sur Terre, même avec les meilleurs
          instruments. Mais ces conditions existent dans certaines nébuleuses…
        </p>
      </Section>
    </LayoutTutorielFR>
  )
}
